import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/userProject/pageContract',
        method: 'GET',
        params
    });
}

// 更改合同状态
export function Enabled(params) {
    return request({
        url: '/admin/userProject/changeIndex',
        method: 'GET',
        params
    });
}

// 获取工种下拉数据
export function getWorkJson(params) {
    return request({
        url: '/admin/project/getWorkJson',
        method: 'GET',
        params
    });
}

// 获取项目下拉数据
export function getProJson(params) {
    return request({
        url: '/admin/project/page',
        method: 'GET',
        params
    });
}
// 重置合同
export function Reset(params) {
    return request({
        url: '/admin/userProject/againContract',
        method: 'GET',
        params
    });
}




