<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 300px"
            v-model="page.project_id"
            placeholder="请选择项目"
             show-search
            :filter-option="filterOption"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in proJson"
              :key="index"
              :value="item.id"
              :title="item.name"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="page.work_id"
            placeholder="请选择工种"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="(item, index) in jobJson"
              showSearch
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :placeholder="['加入开始时间', '加入结束时间']"
            style="width: 300px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: 120px"
            v-model="page.contract_state"
            placeholder="请选择状态"
          >
            <a-select-option value="3">合同正常</a-select-option>
            <a-select-option value="2">合同终止</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/ldht', page ,'contractLog')"
            >导出</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            type="danger"
            :disabled="selectedRowKeys.length == 0"
            @click="
              enabledState(selectedRowKeys, 2, '你确定要批量终止合同吗？')
            "
            icon="close-circle"
            >批量终止合同</a-button
          >
        </a-form-item>
        <!-- <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item> -->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :row-selection="rowSelection"
        :pagination="false"
        :scroll="{ y: tableHeigt }"
      >
        <!-- <template slot="state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">正常</a-tag>
            <a-tag color="orange" v-if="text == '2'">终止</a-tag>
          </template> -->
        <template slot="sex" slot-scope="text, record">
          <span v-if="text == '0'">未知</span>
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
        </template>
        <template slot="ht" slot-scope="text, record">
          <a :href="text" target="_blank">查看合同</a>
        </template>
        <template slot="xy" slot-scope="text, record">
          <a :href="text" target="_blank">查看协议</a>
        </template>
        <template slot="state" slot-scope="text, record">
          <a-tag v-if="text == '1'" color="orange"> 待签合同 </a-tag>
          <a-tag v-if="text == '2'" color="red"> 合同已终止 </a-tag>
          <a-tag v-if="text == '3'" color="green"> 合同正常 </a-tag>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text, record">
         <view-file :data="text"></view-file>
        </template>
        <template slot="contract_state" slot-scope="text, record">
          <a-button
            v-if="text.contract_state == 3"
            style="margin-bottom:5px"
            type="danger"
            size="small"
            @click="() => enabledState(record['id'], 2, '你确定要终止合同吗？')"
            >终止合同</a-button
          >
          <a-button
              v-if="text.contract_state == 3"
              type="primary"
              size="small"
              @click="
                () => resetState(record['id'], '你确定要重置合同吗？')
              "
              >重置合同</a-button
            >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
        :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      selectedRowKeys: [],
      tableHeigt: 0,
      examineVisible: false,
      examineRules: {
        audit_state: [
          { required: true, message: "请选择审核结果", trigger: "change" },
        ],
      },
      viewImgData: "",
      examineData: {
        id: "",
        audit_state: undefined,
        refuse_reason: "",
      },
      imgvisible: false,
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "序号",
          width: 70,
          align: "center",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "姓名",
          dataIndex: "uname",
          width: 80,
        },
        // {
        //   title: "性别",
        //   dataIndex: "sex",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "年龄",
        //   dataIndex: "age",
        //   width: 70,
        //   align: "center",
        // },

        // {
        //   title: "籍贯",
        //   dataIndex: "census_register_seat",
        //   width: 270,
        // },

        // {
        //   title: "进场时间",
        //   dataIndex: "in_time",
        //   width: 180,
        // },
        // {
        //   title: "退场时间",
        //   dataIndex: "out_time",
        //   width: 180,
        // },
        {
          title: "劳动合同编号",
          dataIndex: "user_contract_code",
        },

        {
          title: "项目名称",
          dataIndex: "pname",
        },
 {
          title: "工种",
          dataIndex: "wname",
          width: 80,
          align: "center",
        },
        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "头像",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "合同",
          dataIndex: "contract_addr_url",
          scopedSlots: { customRender: "ht" },
          width: 100,
          align: "center",
        },
        {
          title: "协议",
          dataIndex: "agreement_url",
          scopedSlots: { customRender: "xy" },
          width: 100,
          align: "center",
        },

        {
          title: "状态",
          dataIndex: "contract_state",
          key: "contract_state",
          scopedSlots: { customRender: "state" },
          width: 100,
          align: "center",
        },
        {
          title: "身份证号",
          dataIndex: "idno",
        },
        {
          title: "民族",
          dataIndex: "national",
          width: 70,
          align: "center",
        },
        {
          title: "签名",
          dataIndex: "autograph",
          key: "autograph",
          scopedSlots: { customRender: "idno_positive_img_url" },
          width: 120,
        },
        {
          title: "签署时间",
          dataIndex: "in_time",
          width: 180,
        },
        {
          title: "操作",
          width: 110,
          scopedSlots: { customRender: "contract_state" },
          align: "center",
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        sex: undefined,
        project_id: undefined,
        work_id: undefined,
        start_time: "",
        end_time: "",
        contract_state: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
      proJson: [], // 项目下拉数据
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          console.log(this.selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.contract_state == 2,
            name: "完成",
          },
        }),
      };
    },
  },
  mounted() {
    this.getList();
    this.getWorkJson();
    this.getProJson();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
     filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取工种下拉数据
    async getWorkJson() {
      let res = await Api.getWorkJson({ state: "1" });
      this.jobJson = res.list;
    },
    async getProJson() {
      let res = await Api.getProJson({ limit: 100000, state: "1" });
      this.proJson = res.page.list;
    },
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 重置密码确认
    async resetPassword(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要重置密码吗?",
        onOk() {
          this.resetPwd(id);
        },
        onCancel() {},
      });
    },

    async resetPwd(id) {
      let res = await Api.resetPwd({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    hideModal() {
      this.imgvisible = false;
    },
    // 审核弹框
    async openexamineData(id) {
      this.examineData.id = id;
      this.examineVisible = true;
    },
    /**
     * 审核
     *
     */
    UpdateexamineData() {
      this.$refs.UpdateexamineForm.validate(async (valid) => {
        if (valid) {
          let res = await Api.Updateexamine({ ...this.examineData });
          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.examineVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    // 关闭窗口
    cancel() {
      this.examineVisible = false;
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      console.log(id);
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params;
          if (typeof id == "string" || typeof id == 'number') {
            params = {
              ids: id,
              contract_state: state,
            };
          } else {
            params = {
              ids: id.join(","),
              contract_state: state,
            };
          }
          let res = await Api.Enabled({...params});
          if (res && res["code"] == "0") {
            that.getList();
            that.selectedRowKeys = [];
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    // 重置合同
    resetState(id, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            ids:id
          }
          let res = await Api.Reset({...params});
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
  },
};
</script>

<style scoped>
</style>

